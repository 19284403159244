<template>
    <div>
        <ds-header title="招商房源" :showBack="true"></ds-header>
        <a-form layout="inline" style="margin-bottom: 20px">
            <a-form-item label="项目">
                <a-tree-select :replaceFields="fieldName" @change="projectChange" v-model="searchForm.projectId"
                    tree-default-expand-all style="width: 200px" :tree-data="projectList" placeholder="请选择项目" />
            </a-form-item>
            <a-form-item label="房源位置">
                <a-tree-select style="width: 200px" v-model="searchForm.spaceId" :tree-default-expand-all="false"
                    allow-clear :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }" :tree-data="roomPlaceList"
                    placeholder="请选择房源位置" :replaceFields="roomPlaceFieldName" :getPopupContainer="(triggerNode) => {
                        return triggerNode.parentNode || document.body;
                    }">
                </a-tree-select>
            </a-form-item>
            <a-form-item label="房源名称">
                <a-input v-model.trim="searchForm.houseResourceName" placeholder="请输入房源名称" />
            </a-form-item>
            <a-form-item label="房源类型">
                <a-select placeholder="请选择房源类型" :getPopupContainer="(triggerNode) => {
                    return triggerNode.parentNode || document.body;
                }
                    " v-model="searchForm.houseType" style="width: 200px">
                    <a-select-option v-for="item in houseTypeList" :value="item.value" :key="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="价格区间">
                <a-input-number style="width: 150px;" :min="0" :precision="2" v-model.trim="searchForm.minPrice"
                    placeholder="请输入最低价" /> -
                <a-input-number style="width: 150px;" :min="searchForm.minPrice" :precision="2"
                    v-model.trim="searchForm.maxPrice" placeholder="请输入最高价" />
            </a-form-item>
            <a-form-item label="状态">
                <a-select placeholder="请选择状态" :getPopupContainer="(triggerNode) => {
                    return triggerNode.parentNode || document.body;
                }
                    " v-model="searchForm.publishStatus" style="width: 200px">
                    <a-select-option v-for="item in publishStatusList" :value="item.value" :key="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
        </a-form>
        <div class="button">
            <div><a-button type="primary" @click="add">新增</a-button></div>
            <div><a-button type="primary" @click="serch">
                    <a-icon type="search" />
                    查询
                </a-button>
                <a-button class="marginLeft10" @click="searchClear">
                    <a-icon type="redo" />
                    重置
                </a-button>
            </div>
        </div>
        <a-table :scroll="{ x: 1700 }" style="margin-top: 10px" :columns="columns" @change="pageChange"
            :pagination="pagination.total ? pagination : false" :data-source="dataList" bordered :loading="false"
            :rowKey="(record) => record.id">
            <template #action="row">
                <a-button type="link" size="small" @click="edit(row)">编辑</a-button>
                <a-button type="link" size="small" @click="publishBut(row)"
                    v-if="row.publishStatus == 1 || row.publishStatus === 0">发布</a-button>
                <a-button type="link" size="small"
                    v-if="row.publishStatus == 2 && row.stickStatus == 1 && row.showStickBtn"
                    @click="topUpBut(row)">置顶</a-button>
                <a-button type="link" size="small"
                    v-if="row.publishStatus == 2 && row.stickStatus == 2 && row.showStickBtn"
                    @click="UntopUpBut(row)">取消置顶</a-button>
                <a-button type="link" size="small" v-if="row.publishStatus == 2"
                    @click="unPublishBut(row)">下架</a-button>
                <a-button style="color: #F05656;" type="link" size="small" @click="deleteBut(row)"
                    v-if="row.publishStatus != 2">删除</a-button>
            </template>
        </a-table>

    </div>
</template>

<script>
import moment from 'moment';
import * as api from "@/api/lease";
import store from "@/store";
import { columns, fieldName, houseTypeList, publishStatusList } from "../index"
export default {
    name: 'housingResources',
    data() {
        return {
            columns,
            houseTypeList,
            publishStatusList,
            roomPlaceFieldName: {
                children: 'spaceExtensionInfoList',
                title: 'spaceName',
                key: 'id',
                value: 'id',
            },
            dataList: [],
            fieldName,
            searchForm: {
                projectId: undefined,
                spaceId: undefined,
                publishStatus: undefined,
                minPrice: null,
                maxPrice: null
            },
            roomPlaceList: [],
            roomTypeList: [],
            projectList: [],
            statusList: [],
            //分页数据
            pagination: {
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total) => {
                    return `共 ${total} 条`;
                },
            },
        };
    },
    mounted() {
        this.getProjectList()
        // this.getSpaceList()
        this.getData()

    },
    methods: {
        edit(row) {
            this.$router.push({
                path: '/lease/housingResources/add',
                query: {
                    id: row.id
                },
            });
        },
        deleteBut(row) {
            let that = this
            this.$confirm({
                content: '确认是否删除？',
                async onOk() {
                    const res = await api.deleteHousingResources(row.id);
                    if (res.code === '200') {
                        that.$message.success('删除成功');
                        that.getData()
                    } else {
                        that.$message.error(res.msg);
                    }
                },
            });
        },
        //发布
        publishBut(row) {
            let that = this
            this.$confirm({
                content: '确认是否发布？',
                async onOk() {
                    let obj = {
                        id: row.id,
                        modifyType: 1,
                        status: 2
                    }
                    that.operateData(obj)
                },
            });
        },
        //取消发布
        unPublishBut(row) {
            let that = this
            this.$confirm({
                content: '确认是否取消发布？',
                async onOk() {
                    let obj = {
                        id: row.id,
                        modifyType: 1,
                        status: 1
                    }
                    that.operateData(obj)
                },
            });
        },
        //置顶
        topUpBut(row) {
            let that = this
            this.$confirm({
                content: '确认是否置顶？',
                async onOk() {
                    let obj = {
                        id: row.id,
                        modifyType: 2,
                        status: 2
                    }
                    that.operateData(obj)
                },
            });
        },
        //取消置顶
        UntopUpBut(row) {
            let that = this
            this.$confirm({
                content: '确认是否置顶？',
                async onOk() {
                    let obj = {
                        id: row.id,
                        modifyType: 2,
                        status: 1
                    }
                    that.operateData(obj)
                },
            });
        },
        async operateData(obj) {
            let that = this
            const res = await api.updateStatusHousingResources(obj);
            if (res.code === '200') {
                that.$message.success('操作成功');
                that.getData()
            } else {
                that.$message.error(res.msg);
            }
        },
        add() {
            this.$router.push({
                path: '/lease/housingResources/add',
                query: {
                },
            });
        },
        //分页操作
        pageChange(option) {
            this.pagination.current = option.current;
            this.pagination.pageSize = option.pageSize;
            this.getData();
        },
        serch() {
            this.pagination.current = 1
            this.getData()
        },
        searchClear() {
            this.searchForm = this.$options.data.call(this).searchForm;
            this.roomPlaceList=[]
            this.pagination.current = 1;
            this.pagination.pageSize = 10;
            this.getData()
        },
        async getData() {
            let obj = {
                pageNum: this.pagination.current,
                pageSize: this.pagination.pageSize,
                ...this.searchForm,
                roleId: store.state.common.currentMenuRoleId,
            }
            await api.getHousingResourcesDataList(obj).then((res) => {
                if (res.code === '200' && res.data) {
                    this.dataList = res.data.items;
                    this.pagination.total = res.data.total;
                } else {
                    this.dataList = []
                }
            });
        },
        // 获取项目下拉值
        async getProjectList() {
            await api.getProjectList().then((res) => {
                if (res.code === '200') {
                    this.projectList = res.data;
                }
            });
        },
        projectChange() {
            this.getSpaceList()
        },
        async getSpaceList() {
            await api.getSonSpaceListByProjectId({ projectId: this.searchForm.projectId }).then((res) => {
                if (res.code === '200') {
                    this.roomPlaceList = res.data ? res.data : []
                }
            });
        }

    },
};
</script>

<style scoped lang="scss">
.button {
    display: flex;
    justify-content: space-between;
}

.pageSwitch {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.marginLeft10 {
    margin-left: 10px;
}
</style>